import React from 'react'
import GatsbyLink from 'gatsby-link'
import PropTypes from 'prop-types'

const Link = props => {
  if (
    !props.to.includes('//') &&
    !props.to.includes('mailto:') &&
    !props.to.includes('tel:')
  ) {
    return <GatsbyLink {...props}>{props.children}</GatsbyLink>
  }

  return (
    <a
      {...props}
      href={props.to}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      {props.children}
    </a>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Link
