import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import Link from "../utils/link"

import "./SectorsCompact.scss"

const SectorsCompact = ({ isSmall, data }) => {
  const sectorClass = `sectors-compact__sector ${isSmall ? "is-small" : ""}`
  const titleClass = `sectors-compact__title ${isSmall ? "is-small" : ""}`

  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    if (window) {
      if (window.innerWidth < 900) {
        setIsMobile(true)
      }
    }
  }, [])

  return (
    <section className="sectors-compact">
      {data.allWpSector.edges.map((el, i) => (
        <Link
          key={i}
          to={`/sector/${el.node.slug}/`}
          className={sectorClass}
          style={{
            backgroundImage: `url(${
              isMobile &&
              el?.node?.acf?.menuImageMobile?.localFile?.childImageSharp
                ?.original?.src
                ? el.node.acf.menuImageMobile.localFile.childImageSharp.original
                    .src
                : el.node.acf.menuImage.localFile.childImageSharp.original.src
            })`,
          }}
        >
          <h3 className={titleClass}>
            <span>{el.node.title}</span>
          </h3>
        </Link>
      ))}
    </section>
  )
}

SectorsCompact.propTypes = {
  isSmall: PropTypes.bool,
  data: PropTypes.object,
}

SectorsCompact.defaultProps = {
  isSmall: false,
  data: null,
}

export default function SectorsCompactWrap(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpSector(sort: { fields: menuOrder }) {
            edges {
              node {
                slug
                title
                acf {
                  menuVideo
                  menuImage {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                    }
                  }
                  menuImageMobile {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <SectorsCompact data={data} {...props} />}
    />
  )
}
