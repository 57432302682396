import React from "react"
import PropTypes from "prop-types"

import Link from "../utils/link"

import SectorsCompact from "./SectorsCompact"

import LogoLight from "../assets/images/logo.svg"
import LogoDark from "../assets/images/logo-dark.svg"
import "./header.scss"

const Header = ({ noLogo, isDark, isGalleryPage }) => {
  const [isVisible, setIsVisible] = React.useState(false)

  const toggleMenu = () => {
    setIsVisible(!isVisible)
  }

  const renderLogo = () => {
    if (!noLogo) {
      if (isDark) {
        if (isVisible) {
          return (
            <Link to="/" title="Flip Video" className="header__logo">
              <img src={LogoLight} alt="Flip Video" style={{ maxWidth: 97 }} />
            </Link>
          )
        }
        return (
          <Link to="/" title="Flip Video" className="header__logo">
            <img src={LogoDark} alt="Flip Video" style={{ maxWidth: 97 }} />
          </Link>
        )
      } else {
        return (
          <Link to="/" title="Flip Video" className="header__logo">
            <img src={LogoLight} alt="Flip Video" style={{ maxWidth: 97 }} />
          </Link>
        )
      }
    } else {
      return null
    }
  }

  return (
    <>
      <header className="header">
        <div
          className={`header__background ${isVisible ? "active" : ""}`}
          onClick={toggleMenu}
        />
        <div className="header__container">
          <div>{renderLogo()}</div>
          <nav className={`header__nav ${isVisible ? "active" : ""}`}>
            <div className="header__sectors">
              <SectorsCompact />
            </div>
          </nav>
          <div className="header__buttons">
            <Link
              to="/gallery"
              onClick={(e) => {
                if (window.location.pathname.indexOf("/gallery") !== -1) {
                  e.preventDefault()
                  window.history.back()
                }
              }}
              className={`header__gallery ${isVisible ? "active" : ""} ${
                isDark ? "is-dark" : ""
              }`}
            >
              {isGalleryPage && <span className="header__toggle">Close</span>}
              <i className="fas fa-th fa-sm" style={{ marginLeft: 10 }} />
            </Link>
            <button
              className={`header__toggle ${isVisible ? "active" : ""} ${
                isDark ? "is-dark" : ""
              }`}
              onClick={toggleMenu}
            >
              Menu
            </button>
            <div className={`header__links ${isVisible ? "is-visible" : ""}`}>
              <div className="header__link">
                <Link to="/">Home</Link>
              </div>
              <div className="header__link">
                <Link to="/about/">About</Link>
              </div>
              <div className="header__link">
                <Link to="/contact">Contact</Link>
              </div>
              <div className="header__link">
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault()
                    window.ubookr.addBooking()
                  }}
                >
                  Booking
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  noLogo: PropTypes.bool,
  isDark: PropTypes.bool,
  isGalleryPage: PropTypes.bool,
}

Header.defaultProps = {
  noLogo: false,
  isDark: false,
  isGalleryPage: false,
}

export default Header
